import { FILTERS_EQUAL, VIEW_PERMISSION, MAINTAIN_PERMISSION, FILTERS_IN, FORMAT_DATE_TIME_PRIMARY, LINE_PERFORMANCE_SHOP_ORDER } from "@kraftheinz/common";
import { q as queryString } from "./index6.js";
import { O as ORDER_STATUS_TEXTS, a as ORDER_STATUS_COLORS } from "./shop-order.status.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Shop Orders", "has-active": false, "scroll": { x: 1200 }, "fetch-on-first-mount": false }, scopedSlots: _vm._u([{ key: "create-buttons", fn: function() {
    return [_c("a-button", { staticClass: "mx-2", attrs: { "type": "primary", "loading": _vm.isExporting }, on: { "click": _vm.onExport } }, [_vm._v(" Export ")]), _vm.hasMaintainPermission ? _c("a-button", { attrs: { "type": "primary" }, on: { "click": _vm.onAddClick } }, [_vm._v(" Add Shop Orders ")]) : _vm._e()];
  }, proxy: true }, { key: "customFilter", fn: function() {
    return [_c("a-row", { staticClass: "d-flex flex-wrap justify-content-between w-100 filter-shop-order", attrs: { "type": "flex", "align": "bottom" } }, [_c("a-col", { attrs: { "lg": 16, "sm": 24 } }, [_c("a-row", { attrs: { "gutter": 8, "type": "flex", "align": "bottom" } }, [_c("a-col", { attrs: { "sm": 12, "lg": 3 } }, [_c("select-filter", { staticClass: "status-filter mr-2", attrs: { "label": "Status", "source": "value", "source-label": "text", "data-source": _vm.filterOptions, "value": _vm.filters.status }, on: { "change": function($event) {
      return _vm.filterStatus("status", $event);
    } } })], 1), _c("a-col", { attrs: { "sm": 12, "lg": 6 } }, [_c("select-input", { key: "currentLine", attrs: { "label": "Current Line", "placeholder": "Select Current Line", "advanced-filters": _vm.currentLineFilters, "reference": "line-performance.common.shop-order-lines", "source": "code", "value": _vm.filters.currentLine, "mode": "multiple", "allow-clear": "", "autocomplete": "", "max-tag-count": 1 }, on: { "change": _vm.onCurrentLineChange } })], 1), _c("a-col", { attrs: { "sm": 12, "lg": 6 } }, [_c("select-input", { attrs: { "label": "Shift", "placeholder": "Select Shift", "data-source": _vm.listShifts, "source": "id", "source-label": "shiftDescription", "value": _vm.filters.shift, "autocomplete": "" }, on: { "change": _vm.onShiftChange } })], 1), _c("a-col", { attrs: { "sm": 12, "lg": 6 } }, [_c("date-range-picker", { attrs: { "label": "Date Range", "value": _vm.filters.rangeTime, "receive-value-on-clear": "" }, on: { "change": function($event) {
      return _vm.filterRangeTime($event);
    } } })], 1), _c("a-col", { attrs: { "sm": 12, "lg": 3 } }, [_c("a-button", { attrs: { "icon": "close-circle" }, on: { "click": _vm.onClearFiltersClick } }, [_vm._v(" Clear filters ")])], 1)], 1)], 1), _c("a-col", { attrs: { "lg": 8, "sm": 24 } }, [_c("a-row", { staticClass: "mt-lg-0 mt-3 float-md-right" }, [_c("a-col", { attrs: { "span": 12 } }, [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Search by Shop Order")];
    }, proxy: true }]) }, [_c("keyword-filter", { staticClass: "mr-3 pr-2 pr-lg-0 w-100", attrs: { "field": "ShopOrderNumber", "placeholder": "Search by Shop Order", "operator": _vm.filtersEq } })], 1)], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Search by Item Code")];
    }, proxy: true }]) }, [_c("keyword-filter", { staticClass: "w-100", attrs: { "field": "ItemCode", "placeholder": "Search by Item Code" } })], 1)], 1)], 1)], 1)], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mx-1", attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record, "Do you want to delete this item?", "delete");
    } } })], 1) : _vm._e(), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onEditClick(record);
    } } })], 1)];
  } }]) }, [_c("text-field", { key: "ShopOrderNumber", attrs: { "data-index": "shopOrderNumber", "title": "Shop Order", "sorter": true, "width": 150 } }), _c("text-field", { key: "CurrentLine", attrs: { "data-index": "currentLine", "title": "Current Line", "sorter": true, "width": 150 } }), _c("text-field", { key: "ShiftName", attrs: { "data-index": "shiftName", "title": "Shift", "sorter": true, "width": 150 } }), _c("text-field", { key: "Date", attrs: { "data-index": "date", "parse": _vm.parseDateShift, "title": "Date", "sorter": true, "width": 150 } }), _c("text-field", { key: "StartShiftTime", attrs: { "data-index": "startShiftTime", "parse": _vm.parseDate, "title": "Start Time", "sorter": true, "width": 200 } }), _c("text-field", { key: "EndShiftTime", attrs: { "data-index": "endShiftTime", "parse": _vm.parseDate, "title": "Finish Time", "sorter": true, "width": 200 } }), _c("text-field", { key: "TotalProductionTime", attrs: { "data-index": "totalProductionTime", "parse": _vm.parseTime, "title": "Total Production Time", "sorter": true, "width": 250 } }), _c("text-field", { key: "ItemCode", attrs: { "data-index": "itemCode", "title": "Item Code", "sorter": true, "width": 150 } }), _c("text-field", { key: "ItemDesc", attrs: { "data-index": "itemDesc", "title": "Item Description", "sorter": true, "width": 250 } }), _c("tag-field", { key: "Status", attrs: { "data-index": "status", "color": _vm.getStatusColor, "parse": _vm.parseStatus, "title": "Status", "width": 150 } }), _c("text-field", { key: "TotalUnits", attrs: { "data-index": "totalUnits", "title": "Total Unit Produce", "sorter": true, "width": 200 } }), _c("text-field", { key: "Tonnes", attrs: { "data-index": "tonnes", "title": "Tonnages", "sorter": true, "width": 150 } })], 1);
};
var staticRenderFns$1 = [];
var ListShopOrders_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".status-filter[data-v-26c81091]{min-width:130px}@media screen and (min-width: 769px){.filter-shop-order .float-md-right[data-v-26c81091]{float:right;margin-right:-15px}}.filter-shop-order .keyword-filter[data-v-26c81091]{width:100%}@media screen and (min-width: 769px){.filter-shop-order .keyword-filter[data-v-26c81091]{width:230px}}\n")();
const __vue2_script$1 = {
  name: "ListShopOrders",
  inject: [
    "crud",
    "can",
    "getArea",
    "editRoute",
    "facility",
    "apiUrl",
    "createRoute"
  ],
  data() {
    return {
      filtersEq: FILTERS_EQUAL,
      permissions: {
        view: VIEW_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      },
      filterOptions: [
        {
          value: "all",
          text: "All",
          color: "black"
        },
        {
          value: 0
        },
        {
          value: 1
        },
        {
          value: 2
        },
        {
          value: 3
        }
      ],
      filters: {
        status: { value: "all" },
        rangeTime: null,
        currentLine: ["All"],
        shift: "All"
      },
      filtersIn: FILTERS_IN,
      listShifts: [],
      isExporting: false
    };
  },
  computed: {
    area() {
      return this.getArea();
    },
    currentLineFilters() {
      return [
        ["IsInactive", { operator: this.filtersEq, value: "false" }],
        [
          "AreaID",
          {
            operator: this.filtersEq,
            value: this.area
          }
        ],
        [
          "Status",
          {
            operator: this.filtersEq,
            value: this.filters.status.value != "all" ? `${this.filters.status.value}` : null
          }
        ]
      ];
    },
    hasMaintainPermission() {
      return this.can("maintain");
    },
    facilityValue() {
      return this.facility.value;
    }
  },
  watch: {
    area(newVal) {
      this.crud.setFilter("AreaID", {
        operator: this.filtersEq,
        value: newVal ? newVal : -1
      });
      this.crud.deleteFilter("CurrentLine");
      this.filters.currentLine = ["All"];
      this.crud.deleteFilter("shiftId");
      this.filters.shift = "All";
      this.getShifts();
      this.fetchList();
    },
    facilityValue() {
      this.getShifts();
    }
  },
  created() {
    this.getShifts();
    this.crud.setFilter("AreaID", {
      operator: this.filtersEq,
      value: this.area ? this.area : -1
    });
    if (this.area != 999999) {
      this.fetchList();
    }
  },
  beforeMount() {
    this.filterOptions.filter((key) => key.value !== "all").map((key) => {
      Object.assign(key, {
        text: ORDER_STATUS_TEXTS[key.value],
        color: ORDER_STATUS_COLORS[key.value]
      });
    });
    const statusFilter = this.crud.getFilter("Status");
    const currentLineFilter = this.crud.getFilter("CurrentLine");
    const shiftFilter = this.crud.getFilter("ShiftId");
    const startTimeFilter = this.crud.getQueryString("StartTime");
    const endTimeFilter = this.crud.getQueryString("EndTime");
    if (statusFilter) {
      this.filters.status.value = +statusFilter.value;
    }
    if (currentLineFilter) {
      this.filters.currentLine = currentLineFilter.value.includes(",") ? currentLineFilter.value.split(",") : [currentLineFilter.value];
    }
    if (shiftFilter) {
      this.filters.shift = shiftFilter.value != "All" ? +shiftFilter.value : shiftFilter.value;
    }
    if (startTimeFilter && endTimeFilter) {
      this.filters.rangeTime = [
        this.$moment(startTimeFilter.value).format("YYYY-MM-DD"),
        this.$moment(endTimeFilter.value).format("YYYY-MM-DD")
      ];
    }
  },
  methods: {
    getStatusColor(value) {
      return ORDER_STATUS_COLORS[value];
    },
    parseStatus(value) {
      return ORDER_STATUS_TEXTS[value];
    },
    fetchList() {
      const { pageSize } = this.crud.getPagination();
      this.crud.setPagination({ page: 1, pageSize });
      this.crud.fetchList();
    },
    parseDate(value) {
      if (!value)
        return "";
      return this.$moment(value).format(FORMAT_DATE_TIME_PRIMARY);
    },
    parseDateShift(value) {
      if (!value)
        return "";
      const hour = this.$moment(value).hours();
      if (+hour < 6) {
        return this.$moment(value).subtract(1, "days").format("DD/MM/YYYY");
      }
      return this.$moment(value).format("DD/MM/YYYY");
    },
    filterStatus(key, status) {
      this.filters[key] = { value: status.value };
      this.filters.currentLine = ["All"];
      this.filters.rangeTime = null;
      this.filters.shift = "All";
      this.crud.deleteFilter("CurrentLine");
      this.crud.deleteFilter("ShiftId");
      this.crud.deleteQueryString("StartTime");
      this.crud.deleteQueryString("EndTime");
      this.crud.deleteFilter("ShopOrderNumber");
      this.crud.deleteFilter("ItemCode");
      if (status.value == "all") {
        this.crud.deleteFilter("Status");
        this.fetchList();
        return;
      }
      this.crud.setFilter("Status", {
        operator: this.filtersEq,
        value: `${status.value}`
      });
      this.fetchList();
    },
    filterRangeTime(time) {
      this.crud.deleteFilter("ShopOrderNumber");
      this.crud.deleteFilter("ItemCode");
      if (!time) {
        this.filters.rangeTime = null;
        this.crud.deleteQueryString("StartTime");
        this.crud.deleteQueryString("EndTime");
        this.fetchList();
        return;
      }
      const { startDate, endDate } = time;
      this.filters.rangeTime = [startDate, endDate];
      const startTime = this.$moment(time.startDate).startOf("day").toISOString();
      const endTime = this.$moment(time.endDate).endOf("day").toISOString();
      this.crud.setQueryString("StartTime", startTime);
      this.crud.setQueryString("EndTime", endTime);
      this.fetchList();
    },
    onClearFiltersClick() {
      this.filters.status = { value: "all" };
      this.filters.rangeTime = null;
      this.filters.currentLine = ["All"];
      this.filters.shift = "All";
      this.crud.deleteFilter("Status");
      this.crud.deleteFilter("ShopOrderNumber");
      this.crud.deleteFilter("ItemCode");
      this.crud.deleteFilter("CurrentLine");
      this.crud.deleteFilter("ShiftId");
      this.crud.deleteQueryString("StartTime");
      this.crud.deleteQueryString("EndTime");
      this.fetchList();
    },
    parseTime(value) {
      const time = +value;
      const m = Math.floor(time / 60);
      const s = time - m * 60;
      const mDisplay = m > 0 ? m + (m === 1 ? " minute " : " minutes ") : "";
      const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
      return mDisplay + sDisplay;
    },
    onCurrentLineChange(value) {
      this.filters.shift = "All";
      this.filters.rangeTime = null;
      this.crud.deleteFilter("ShiftId");
      this.crud.deleteQueryString("StartTime");
      this.crud.deleteQueryString("EndTime");
      this.crud.deleteFilter("ShopOrderNumber");
      this.crud.deleteFilter("ItemCode");
      if (value.length == 1 && value[0] == "All") {
        this.filters.currentLine = value;
        this.crud.deleteFilter("CurrentLine");
      } else if (value[0] != "All" && value.includes("All") || value.length == 0) {
        this.filters.currentLine = ["All"];
        this.crud.deleteFilter("CurrentLine");
      } else {
        this.filters.currentLine = value.filter((val) => val != "All");
        this.crud.setFilter("CurrentLine", {
          operator: this.filtersIn,
          value: this.filters.currentLine
        });
      }
      this.fetchList();
    },
    onShiftChange(value) {
      this.filters.shift = value;
      if (value == "All") {
        this.crud.deleteFilter("ShiftId");
        this.crud.fetchList();
        return;
      }
      this.crud.setFilter("ShiftId", {
        operator: this.filtersEq,
        value
      });
      this.crud.fetchList();
    },
    onEditClick(record) {
      const editRoute = this.editRoute.replace(":id", record.shopOrderId ? record.shopOrderId + `?shiftId=${record.id}` : record.id);
      this.$router.push(editRoute);
    },
    async getShifts() {
      const { data } = await this.axios.get(`${this.apiUrl}/line-performance/common/shifts?areaId=${this.area}&facilityId=${this.facility.value}`);
      this.listShifts = [{ id: "All", shiftDescription: "All" }, ...data];
    },
    onAddClick() {
      this.$router.push(this.createRoute);
    },
    async onExport() {
      this.isExporting = true;
      const filterString = this.crud.getFilterQuery();
      const queryString$1 = this.crud.getQueryStrings();
      const timezone = this.$moment().format("Z");
      const params = queryString.parse(`${filterString}&${queryString$1}&timeZone=${timezone}`);
      try {
        const { data, headers, status } = await this.axios.get(`${this.apiUrl}/line-performance/shop-orders/export`, {
          params,
          responseType: "blob"
        });
        const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", objectUrl);
        link.setAttribute("download", headers.filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        const { message } = JSON.parse(await error.response.data.text());
        this.$notification.error({
          message
        });
      } finally {
        this.isExporting = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "26c81091", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListShopOrders = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-shop-orders" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "line-performance.common.shop-order-lines", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "line-performance.shop-orders", "api-url": _vm.apiUrl, "edit-route": "/line-performance/shop-orders/:id", "create-route": "/line-performance/shop-orders/create", "page": _vm.page } }, [_c("list-shop-orders")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListShopOrders
  },
  data() {
    return {
      apiUrl,
      page: LINE_PERFORMANCE_SHOP_ORDER,
      itemsMenu: [
        {
          key: "shopOrders",
          title: "Shop Orders",
          path: "/line-performance/shop-orders"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
