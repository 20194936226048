const ORDER_STATUS_SCHEDULED = 0;
const ORDER_STATUS_RUNNING = 1;
const ORDER_STATUS_PAUSED = 2;
const ORDER_STATUS_FINISHED = 3;
const ORDER_STATUS_COLORS = {
  [ORDER_STATUS_SCHEDULED]: "orange",
  [ORDER_STATUS_RUNNING]: "blue",
  [ORDER_STATUS_PAUSED]: "red",
  [ORDER_STATUS_FINISHED]: "gray"
};
const ORDER_STATUS_TEXTS = {
  [ORDER_STATUS_SCHEDULED]: "Scheduled",
  [ORDER_STATUS_RUNNING]: "Running",
  [ORDER_STATUS_PAUSED]: "Paused",
  [ORDER_STATUS_FINISHED]: "Finished"
};
export { ORDER_STATUS_TEXTS as O, ORDER_STATUS_COLORS as a, ORDER_STATUS_FINISHED as b, ORDER_STATUS_SCHEDULED as c, ORDER_STATUS_RUNNING as d, ORDER_STATUS_PAUSED as e };
